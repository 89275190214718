<template>
	<div>

		<div class="mb-10">

			<div class="text-lg font-bold mb-5">
				Personal
			</div>
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Gender</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="transgender-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('gender') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Address</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="map-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('address') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">State</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="map-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('state') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Lga</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="map-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('lga') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Education</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="medal-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('education') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Marital_status</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="people-circle-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('marital_status') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">BVN</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="finger-print-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('bvn') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Date of Birth</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('date_of_birth') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Bank Name</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
						<template v-if="profileData('bank_name') != 'N/A'">
							{{ profileData('bank_name') | bankName($store.state.banks) }}
						</template>
						<template v-else>
							N/A
						</template>
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Account no</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('account_no') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">NIN</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="finger-print-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('nin') }}
					</div>
				</div>
				<!-- <div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Id Number</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="calculator-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('id_number') }}
					</div>
				</div> -->
			</div>			
		</div>

		<div class="mb-10" v-if="isEmployed">
			<div class="text-lg font-bold mb-5">
				Employment Information
			</div>
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">{{ isEmployed ? 'Employer' : 'Business Name' }}</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
						{{ staff.company ? staff.company.name : profileData('employer') ? profileData('employer') : 'N/A'  }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">{{ isEmployed ? 'Company' : 'Business' }} Industry</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_industry') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">{{ isEmployed ? 'Company' : 'Business' }} Address</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="map-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_address') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200" v-if="isEmployed">
					<div class="opacity-75 text-sm font-medium mb-1">Workplace Email</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="mail-outline" class="text-lg mr-2"></ion-icon>
						{{ staff.official_email }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px" v-if="isEmployed">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Employment Type</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('emp_type') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Job Title</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('position') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">{{ isEmployed ? 'Salary' : 'Income' }} Day</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('salary_day') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">{{ isEmployed ? 'Salary' : 'Monthly Income' }}</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('salary') }}
					</div>
				</div>
			</div>
		</div>

		<div class="mb-10" v-else>
			<div class="text-lg font-bold mb-5">
				Business Information
			</div>
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Business Name</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="business-outline" class="text-lg mr-2"></ion-icon>
						{{ staff.company ? staff.company.name : profileData('employer') ? profileData('employer') : 'N/A'  }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Business Industry</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_industry') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Business Description</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_description', 'No description provided') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Business Size</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_size', "Doesn't have more staff") }} Staff
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Registration Number</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_registration_number', 'Not Registered') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Date Opened</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_start_month') }} {{ profileData('company_start_year') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Monthly Income</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_monthly_income') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Monthly Profit</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_monthly_profit') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Business Address</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="map-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_address', 'No office space') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Business Website</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="globe-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('company_website', 'No website') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Twitter</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="logo-twitter" class="text-lg mr-2"></ion-icon>
						{{ profileData('twitter', 'No Twitter account') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Instagram</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="logo-instagram" class="text-lg mr-2"></ion-icon>
						{{ profileData('instagram', 'No Instagram account') }}
					</div>
				</div>
			</div>

			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Income Day</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('salary_day') }}
					</div>
				</div>
				<div class="col-span-1 p-4 border-t xl:border-t-0 xl:border-l border-blue-200">
					<div class="opacity-75 text-sm font-medium mb-1">Monthly Income</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="cash-outline" class="text-lg mr-2"></ion-icon>
						{{ profileData('salary') }}
					</div>
				</div>
			</div>
			
			<div class="grid grid-cols-1 xl:grid-cols-2 border border-blue-200" style="margin-top: -1px">
				<div class="col-span-1 p-4">
					<div class="opacity-75 text-sm font-medium mb-1">Previous Formal Job</div>
					<div class="text-gray-500 text-sm font-light flex flex-row items-center">
						<ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
						<template v-if="profileData('previous_formal_job')">
							<span>Previously worked at</span>
							<span class="font-bold ml-1">{{ profileData('previous_formal_job') }}</span>
						</template>
						<template v-else>
							Hasn't worked a formal job before
						</template>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	export default {
		props: {
			staff: {
				type: Object,
				default: null
			}
		},
		data() {
			return {
				approval: this.$options.basicForm([]),
				creditHistory: null,
				creditHistoryColumns: [
					{
						name: 'SubscriberName',
						th: 'Lender'
					},
					{
						name: 'OpeningBalanceAmt',
						th: 'Amount',
						render: (loan, amount) => `₦ ${amount}`
					},
					{
						name: 'InstalmentAmount',
						th: 'Instalment',
						render: (loan, amount) => `₦ ${amount}`
					},
					{
						name: 'LoanDuration',
						th: 'Duration',
						render: (loan, duration) => `${duration} Months`
					},
					{
						name: 'AccountStatus',
						th: 'Status'
					}
				],
				creditHistoryError: null,
				creditHistoryLoading: false,
				creditHistoryLoaded: false,
				loading: false,
				loanColumns: [
					{
						name: 'loan_amount',
						th: 'Loan Amount',
						render: (loan, amount) => `₦ ${this.$options.filters.currency(amount)}`
					},
					{
						name: 'loan_type',
						th: 'Loan Type',
						render: (loan, type) => this.$options.filters.sentenceCase(type.replace('_', ' ')) + ' Loan'
					},
					{
						name: 'tenure',
						th: 'Loan Tenure',
						render: (loan, tenure) => `${tenure} Months`
					},
					{
						name: 'status',
						th: 'Status',
						render: (loan, status) => {
							switch (status) {
								case 'approved':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Approved
									</div>`;
								case 'declined':
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
								case 'pending':
								default:
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							}
						}
					},
					{
						name: 'created_at',
						th: 'Request Date',
						render: (loan, created_at) => this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
					}
				]
			}
		},
		computed: {
			bankStatements() {
				return this.staff?.bank_statements || [];
			},
			currentLoan() {
				return this.staff?.active_loans?.[0];
			},
			currentLoanType() {
				const loanType = this.currentLoan?.loan_type;

				switch (loanType) {
					case 'standard':
						return 'Standard Loan';
					case 'pay_day':
						return 'Pay Day Loan';
					default:
						return this.currentLoan?.loan_type || 'None';
				}

			},
			currentLoanAmount() {
				return this.currentLoan?.loan_amount || 0;
			},
			employmentStatus() {
				return this.staff?.profile?.employment_status;
			},
			isEmployed() {
				return this.employmentStatus == 'employed';
			},
			lastBankStatement() {
				return this.bankStatements[this.bankStatements.length - 1];
			},
			position() {
				return this.staff?.profile ? this.staff?.profile.position : 'Staff';
			},
			staffId() {
				return this.$route.params.staffId;
			},
			totalLoanAmount() {
				return this.loans && this.loans.reduce( (last, transaction) => last + Number(transaction.loan_amount), 0 );
			},
			loans() {
				return this.staff?.loan || [];
			},
			loanHistory() {
				return this.creditHistory?.account_summary || [];
			},
			totalCreditLoans() {
				const writtenoff = this.loanCount('writtenoff');
				const open = this.loanCount('open');
				const closed = this.loanCount('closed');
				console.log(writtenoff);
				console.log(open);
				console.log(closed);

				return writtenoff + open + closed;
			},
			hasStaffRole() {
				return this.staff?.roles?.find(role => role.slug == 'company_staff')
			}
		},
		methods: {
			loanCount(key) {
				const loan_counts = this.creditHistory?.total_summary?.loan_count;
				return loan_counts && loan_counts[key] ? loan_counts[key] : 0;
			},
			profileData(key, default_value = 'N/A') {
				const data = this.staff?.profile?.[key];
				return data ? data : default_value;
			},
		}
	}
</script>